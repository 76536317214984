<template>
  <div>
    <Add
      v-if="showType != 'view'"
      v-model:addForm="addForm"
      :detailForm="detailForm"
      :searchList="searchList"
    ></Add>
    <View v-else :detailForm="detailForm"></View>
  </div>
</template>

<script>
import Add from "./version/Add.vue";
import View from "./version/View.vue";
import _ from "lodash";
export default {
  data() {
    return {
      addForm: {},
    };
  },
  props: {
    showType: {
      type: String,
      default: "",
    },
    detailForm: {
      type: Object,
      default: () => {},
    },
    searchList: {
      type: Object,
      default: () => {},
    },
    submitForm: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["update:submitForm"],
  watch: {
    addForm: {
      handler(val) {
        let submit = _.mergeWith(this.submitForm, val, (objV, srcV) => {
          return srcV
        });
        this.$emit("update:submitForm", submit);
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {},
  components: {
    Add,
    View,
  },
};
</script>

<style scoped lang="scss"></style>
