<template>
  <!-- 基本信息 -->
  <div class="baseinfo">
    <div class="row mb-4 justify-content-between">
      <div class="col-3">
        <div>
          <label class="form-label">{{ $t("attribute.name") }}</label>
          <input type="text" class="form-control" v-model="base_info.name" />
        </div>
      </div>
      <div class="col-3">
        <div>
          <label class="form-label">{{ $t("attribute.type") }}</label>
          <input type="text" class="form-control" v-model="base_info.type" />
        </div>
      </div>
      <div class="col-3">
        <div>
          <label class="form-label">{{ $t("attribute.size") }}</label>
          <input type="text" class="form-control" v-model="base_info.sizing" />
        </div>
      </div>
      <div class="col-3">
        <div>
          <label class="form-label">{{ $t("attribute.fitting") }}</label>
          <Multiselect
            id="fitting"
            class="form-control"
            v-model="base_info.fitting"
            label="value"
            value-prop="key"
            :close-on-select="true"
            :searchable="true"
            :create-option="false"
            :options="fit_options"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <div>
          <label class="form-label">{{ $t("attribute.code") }}</label>
          <Multiselect
            id="item"
            class="form-control"
            v-model="base_info.code_1"
            label="title"
            value-prop="code"
            :close-on-select="true"
            :searchable="true"
            :create-option="false"
            :options="code_options"
            @select="selectCode"
            @clear="clearCode"
          />
        </div>
      </div>
      <div class="col-3">
        <div
          style="position: relative; top: 100%; transform: translateY(-100%)"
        >
          <Multiselect
            id="item"
            class="form-control"
            v-model="hscode"
            label="title"
            value-prop="code"
            :close-on-select="true"
            :searchable="true"
            :create-option="false"
            :options="sub_list"
            @select="getCodeTitle"
            @clear="clearCodeTitle"
          />
        </div>
      </div>
      <div class="col-3">
        <div
          style="position: relative; top: 100%; transform: translateY(-100%)"
        >
          <input
            v-model="base_info.code_2"
            @input="base_info.code_2 = stringRegEx(0, base_info.code_2, 2)"
            type="text"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-3">
        <div
          style="position: relative; top: 100%; transform: translateY(-100%)"
        >
          <input
            v-model="base_info.code_ciq"
            @input="base_info.code_ciq = stringRegEx(1, base_info.code_ciq, 3)"
            type="text"
            class="form-control"
          />
        </div>
      </div>
    </div>
  </div>

  <FabricAddTable v-model:selectedList="base_info.fabrics"></FabricAddTable>
  <UploadFileView
    v-model:selectedList="style_preview"
    :title="$t('attribute.style')"
    :hint="$t('base.upload_picture_limit')"
  ></UploadFileView>
</template>

<script>
import FabricAddTable from "@/views/components/FabricAddTable.vue";
import UploadFileView from "@/views/components/UploadFileView.vue";
import { fit_options } from "../../datalist";
import _ from "lodash";
import { getHSCode } from "@/service/index";
import { getFileValuesFromDetail } from "@/utils/fetch-data";
import { stringRegEx } from "@/utils/common.js";
import { getHscodeDbIndex } from "@/service/index";
export default {
  components: {
    FabricAddTable,
    UploadFileView,
  },
  props: {
    addForm: {
      type: Object,
      default: () => {},
    },
    detailForm: {
      type: Object,
      default: () => {},
    },
    searchList: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["update:addForm", 'update:hscode', 'update:hscodeTitle', 'update:associatedIndex'],
  data() {
    return {
      base_info: {
        code_1: "",
        code_2: "",
        code_ciq: "",
        name: "",
        type: "",
        sizing: "",
        fitting: "",
        code: "",
        fabrics: [],
        style: [],
      },
      fit_options,
      code_options: [],
      sub_list: [],
      /* style文件预览 */
      style_preview: [],
      hscode: null,
      hscodeTitle: null,
      associatedIndex:{}, // 关联的指标
      inited: false,
    };
  },
  methods: {
    stringRegEx,
    selectCode(init) {
      const self = this;
      const index = _.findIndex(this.code_options, function (o) {
        return o.code == self.base_info.code_1;
      });
      if (init != "init") {
        this.base_info.code = "";
      }
      if (index >= 0 && index < this.code_options.length) {
        const item = this.code_options[index];
        this.sub_list = item.sub_list;
        if (this.hscode && init == "init") {
          // 获取codeTitle
          const index = _.findIndex(this.sub_list, function (o) {
            return o.code == self.hscode;
          });
          if (index >= 0 && index < this.sub_list.length) {
            const item = this.sub_list[index];
            this.hscodeTitle = item.title;
            this.$emit('update:hscodeTitle', item.title)
          }
        }
      }
    },
    clearCode() {
      this.base_info.code = "";
      this.sub_list = [];
    },
    getCodeTitle(code) {
      console.log('code变化：', code)
      if (!code) {
        this.hscodeTitle = "";
        this.associatedIndex = {};
        return;
      }
      this.base_info.code = code;
      const index = _.findIndex(this.sub_list, function (o) {
        return o.code == code;
      });
      if (index >= 0 && index < this.sub_list.length) {
        const item = this.sub_list[index];
        this.hscodeTitle = item.title;
      }
      // 获取关联的指标
      const params = {
        code: code,
        key: 'garment'
      };
      getHscodeDbIndex(params)
        .then((res) => {
          if (res && res.payload) {
            this.associatedIndex = res.payload
            this.$emit('update:associatedIndex', this.associatedIndex)
            this.$emit('update:hscodeTitle', this.hscodeTitle)
            this.$emit('update:hscode', code)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clearCodeTitle() {
      this.hscodeTitle = "";
      this.associatedIndex = {};
    },
    requestHSCode(key) {
      if (this.code_options.length) {
        return
      }
      const params = {
        key: key,
      };
      getHSCode(params)
        .then((res) => {
          if (res && res.payload) {
            this.code_options = res.payload;
            this.selectCode("init");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    style_preview: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          const list = _.map(this.style_preview, "id");
          this.base_info.style = list;
        }
      },
    },
    detailForm: {
      handler(val) {
        if (val && !this.inited) {
          this.inited = true;
          // 编辑初始化内容，拷贝出来赋值，避免对可变对象的改变，导致detailForm改变重复进入监听
          const detailFormData = _.cloneDeep(val);
          this.base_info.name = _.get(detailFormData, "name", "");
          this.base_info.type = _.get(detailFormData, "type", "");
          this.base_info.sizing = _.get(detailFormData, "sizing", "");
          this.base_info.fitting = _.get(detailFormData, "fitting", "");
          // 如果没有code，就不要赋值，避免影响下面的selectCode
          if (detailFormData.code && !this.hscode) {
            this.base_info.code = _.get(detailFormData, "code", "");
            this.hscode = this.base_info.code;
            console.log('hscode初始赋值：', this.hscode)

            // 获取关联的指标
            const params = {
              code: this.hscode,
              key: 'garment'
            };
            getHscodeDbIndex(params)
                .then((res) => {
                  if (res && res.payload) {
                    this.associatedIndex = res.payload
                    this.$emit('update:associatedIndex', this.associatedIndex)
                  }
                })
                .catch((err) => {
                  console.log(err);
                });

            if (this.base_info.code && this.base_info.code.length == 6) {
              this.base_info.code_1 = this.base_info.code.slice(0, 4);
              this.selectCode("init");
            }
          }
          this.base_info.code_2 = _.get(detailFormData, "code_2", "");
          this.base_info.code_ciq = _.get(detailFormData, "code_ciq", "");

          this.base_info.fabrics = _.get(detailFormData, "fabrics", []);
          if (!this.base_info.style.length) {
            const style_data = getFileValuesFromDetail(detailFormData, "style");
            this.base_info.style = style_data.ids;
            this.style_preview = style_data.previews;
          }
        }
        this.requestHSCode("garment");
      },
      deep: true,
      immediate: true,
    },
    searchList: {
      handler(val) {
        if (val) {
          // TODO： 这里等搜索完成后，再考虑要不要通过搜索的内容来提供合身度选项。
          // console.log('通过searchList监听修改fit_options(合身度选项)。searchList:', val, 'fit_options:', val.fitting)
          // this.fit_options = val.fitting;
        }
      },
      deep: true,
      immediate: true,
    },
    base_info: {
      handler(val) {
        let re_form = _.cloneDeep(val);
        console.log("base_info", re_form);
        this.$emit("update:addForm", re_form);
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss"></style>
