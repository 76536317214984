<template>
  <div
    :class="[
      'hand_feel',
      $route.query.chooseType &&
        $route.query.chooseType == 'hand' &&
        'choose_border',
    ]"
  >
    <label>{{ $t("radar_chart.feel") }}</label>
    <div class="card-header" style="padding: 0.2rem 1rem"></div>
    <b-row>
      <b-col>
        <div class="hand_img">
          <img
            style="width: 100%; height: 400px; object-fit: contain"
            src="@/assets/img/hand_feel.png"
            alt=""
          />
        </div>
      </b-col>
      <b-col cols="4">
        <div
          class="hand_info d-flex flex-column justify-content-between"
          style="position: relative; top: 50%; transform: translateY(-50%)"
        >
          <div class="py-3">
            <label class="form-label" :class="[!isAdmin() ? 'sample-title' : '', !isAdmin() ? 'sample-title-first' : '']">{{ $t("radar_chart.smoothness") }}:</label>
            <b-row>
              <b-col v-if="isAdmin()">
                <div class="d-flex">
                  <input
                    type="text"
                    class="form-control"
                    :value="hand_feel_star.roughness"
                    disabled
                  />
                  <div class="text-warning fs-20 ms-2">
                    <i
                      v-for="(cls, idx) in starUI(hand_feel_data.roughness)"
                      :key="idx"
                      :class="cls"
                    ></i>
                  </div>
                </div>
              </b-col>
              <b-col>
                <feel-tip-bar bar-type="hand_roughness" :value="hand_feel_data.roughness"></feel-tip-bar>
              </b-col>
            </b-row>
          </div>
          <div class="py-3">
            <label class="form-label" :class="[!isAdmin() ? 'sample-title' : '']">{{ $t("radar_chart.softness") }}:</label>
            <b-row>
              <b-col v-if="isAdmin()">
                <div class="d-flex">
                  <input
                    type="text"
                    class="form-control"
                    :value="hand_feel_star.softness"
                    disabled
                  />
                  <div class="text-warning fs-20 ms-2">
                    <i
                      v-for="(cls, idx) in starUI(hand_feel_data.softness)"
                      :key="idx"
                      :class="cls"
                    ></i>
                  </div>
                </div>
              </b-col>
              <b-col>
                <feel-tip-bar bar-type="hand_softness" :value="hand_feel_data.softness"></feel-tip-bar>
              </b-col>
            </b-row>
          </div>
          <div class="py-3">
            <label class="form-label" :class="[!isAdmin() ? 'sample-title' : '']">{{ $t("radar_chart.warmth") }}:</label>
            <b-row>
              <b-col v-if="isAdmin()">
                <div class="d-flex">
                  <input
                    type="text"
                    class="form-control"
                    :value="hand_feel_star.warmth"
                    disabled
                  />
                  <div class="text-warning fs-20 ms-2">
                    <i
                      v-for="(cls, idx) in starUI(hand_feel_data.warmth)"
                      :key="idx"
                      :class="cls"
                    ></i>
                  </div>
                </div>
              </b-col>
              <b-col>
                <feel-tip-bar bar-type="hand_warmth" :value="hand_feel_data.warmth"></feel-tip-bar>
              </b-col>
            </b-row>
          </div>
          <div class="py-3" v-if="isAdmin()">
            <label class="form-label">{{ $t("radar_chart.total") }}:</label>
            <b-row>
              <b-col>
                <div class="d-flex">
                  <input
                    type="text"
                    class="form-control"
                    :value="hand_feel_star.total"
                    disabled
                  />
                  <div class="text-warning fs-20 ms-2">
                    <i
                      v-for="(cls, idx) in starUI(hand_feel_data.total)"
                      :key="idx"
                      :class="cls"
                    ></i>
                  </div>
                </div>
              </b-col>
              <b-col></b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col>
        <div class="hand_chart ms-5 position-relative">
          <div :id="handChartId" style="height: 410px; width: 470px"></div>
          <div class="chart-options d-flex align-items-center">
            <div>{{$t('fabric.id')}}:</div>
            <div>
              <div class="btn-group" role="group">
                <button id="btnGroupVerticalDrop1" type="button" class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="padding: 0 3px;">
                  {{ currentHandFabric.fabric_id }}
                </button>
                <div class="dropdown-menu" aria-labelledby="btnGroupVerticalDrop1">
                  <span v-for="(hItem,hIndex) in handFabrics" :key="hIndex" class="dropdown-item" @click="setCurrentHandFabric(hItem)">{{hItem.fabric_id}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
  <div
    :class="[
      'skin_feel',
      $route.query.chooseType &&
        $route.query.chooseType == 'skin' &&
        'choose_border',
    ]"
    style="padding-bottom: 2rem;"
  >
    <label>{{ $t("radar_chart.skin") }}</label>
    <div class="card-header" style="padding: 0.2rem 1rem"></div>
    <b-row>
      <b-col>
        <div class="skin_img">
          <img
            style="width: 100%; height: 400px; object-fit: contain"
            src="@/assets/img/skin_comfort.png"
            alt=""
          />
        </div>
      </b-col>
      <b-col cols="4">
        <div
          class="skin_info d-flex flex-column justify-content-between"
          style="position: relative; top: 50%; transform: translateY(-50%)"
        >
          <div class="py-3">
            <label class="form-label" :class="[!isAdmin() ? 'sample-title' : '', !isAdmin() ? 'sample-title-first' : '']">{{ $t("radar_chart.smoothness") }}:</label>
            <b-row>
              <b-col v-if="isAdmin()">
                <div class="d-flex align-items-center">
                  <input
                    type="text"
                    class="form-control"
                    :value="skin_feel_star.prickle"
                    disabled
                  />
                  <div class="text-warning fs-20 ms-2">
                    <i
                      v-for="(cls, idx) in starUI(skin_feel_data.prickle)"
                      :key="idx"
                      :class="cls"
                    ></i>
                  </div>
                </div>
              </b-col>
              <b-col>
                <feel-tip-bar bar-type="skin_roughness" :value="skin_feel_data.prickle"></feel-tip-bar>
              </b-col>
            </b-row>

          </div>
          <div class="py-3">
            <label class="form-label" :class="[!isAdmin() ? 'sample-title' : '']">{{ $t("radar_chart.softness") }}:</label>
            <b-row>
              <b-col v-if="isAdmin()">
                <div class="d-flex align-items-center">
                  <input
                    type="text"
                    class="form-control"
                    :value="skin_feel_star.softness"
                    disabled
                  />
                  <div class="text-warning fs-20 ms-2">
                    <i
                      v-for="(cls, idx) in starUI(skin_feel_data.softness)"
                      :key="idx"
                      :class="cls"
                    ></i>
                  </div>
                </div>
              </b-col>
              <b-col>
                <feel-tip-bar bar-type="skin_softness" :value="skin_feel_data.softness"></feel-tip-bar>
              </b-col>
            </b-row>
          </div>
          <div class="py-3">
            <label class="form-label" :class="[!isAdmin() ? 'sample-title' : '']">{{ $t("radar_chart.warmth") }}:</label>
            <b-row>
              <b-col v-if="isAdmin()">
                <div class="d-flex align-items-center">
                  <input
                    type="text"
                    class="form-control"
                    :value="skin_feel_star.warmth"
                    disabled
                  />
                  <div class="text-warning fs-20 ms-2">
                    <i
                      v-for="(cls, idx) in starUI(skin_feel_data.warmth)"
                      :key="idx"
                      :class="cls"
                    ></i>
                  </div>
                </div>
              </b-col>
              <b-col>
                <feel-tip-bar bar-type="skin_warmth" :value="skin_feel_data.warmth"></feel-tip-bar>
              </b-col>
            </b-row>
          </div>
          <div class="py-3" v-if="isAdmin()">
            <label class="form-label">{{ $t("radar_chart.total") }}:</label>
            <b-row>
              <b-col>
                <div class="d-flex align-items-center">
                  <input
                    type="text"
                    class="form-control"
                    :value="skin_feel_star.total"
                    disabled
                  />
                  <div class="text-warning fs-20 ms-2">
                    <i
                      v-for="(cls, idx) in starUI(skin_feel_data.total)"
                      :key="idx"
                      :class="cls"
                    ></i>
                  </div>
                </div>
              </b-col>
              <b-col></b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col>
        <div class="skin_chart ms-5 position-relative">
          <div :id="skinChartId" style="height: 410px; width: 470px"></div>
          <div class="chart-options d-flex align-items-center">
            <div>{{$t('fabric.id')}}:</div>
            <div>
              <div class="btn-group" role="group">
                <button id="btnGroupVerticalDrop1" type="button" class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="padding: 0 3px;">
                  {{ currentSkinFabric.fabric_id }}
                </button>
                <div class="dropdown-menu" aria-labelledby="btnGroupVerticalDrop1">
                  <span v-for="(hItem,hIndex) in skinFabrics" :key="hIndex" class="dropdown-item" @click="setCurrentSkinFabric(hItem)">{{hItem.fabric_id}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { radarChart } from "@/utils/chart.js";
import _ from "lodash";
import { starUI } from "@/utils/common";
import { isAdmin } from "@/utils/permission.js";
import FeelTipBar from "@/components/feel-tip-bar.vue";
export default {
  props: {
    detailForm: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      handChartId: `hand${+new Date()}`,
      skinChartId: `skin${+new Date()}`,
      hand_feel_star: {
        roughness: "",
        softness: "",
        warmth: "",
        total: "",
      },
      hand_feel_data: {
        roughness: "",
        softness: "",
        warmth: "",
        total: "",
      },
      hand_feel_score: {
        roughness: "",
        softness: "",
        warmth: "",
        total: "",
      },
      skin_feel_star: {
        roughness: "",
        softness: "",
        warmth: "",
        total: "",
      },
      skin_feel_data: {
        prickle: "",
        softness: "",
        warmth: "",
        total: "",
      },
      skin_feel_score: {
        prickle: "",
        softness: "",
        warmth: "",
        total: "",
      },
      currentHandFabric: {},
      currentSkinFabric: {},
      handFabrics: [],
      skinFabrics: [],
      name: "",
    };
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.initChart();
    // });
    console.log('1111', isAdmin())
  },
  methods: {
    starUI,
    isAdmin,
    initChart() {
      this.hand_feel_chart();
      this.skin_feel_chart();
    },

    hand_feel_chart() {
      let options = {
        data: [
          this.get_number_default(this.hand_feel_data.softness),
          this.get_number_default(this.hand_feel_data.roughness),
          this.get_number_default(this.hand_feel_data.warmth),
          this.get_number_default(this.hand_feel_data.total),
        ],
        ave: [
          this.get_number_default(this.hand_feel_score.softness),
          this.get_number_default(this.hand_feel_score.roughness),
          this.get_number_default(this.hand_feel_score.warmth),
          this.get_number_default(this.hand_feel_score.total),
        ],
        color: "#50C3E6",
        name: this.name,
        chartDom: document.querySelector(`#${this.handChartId}`),
      };
      radarChart(options, 'pc', 'hand');
    },
    skin_feel_chart() {
      let options = {
        data: [
          this.get_number_default(this.skin_feel_data.softness),
          this.get_number_default(this.skin_feel_data.prickle),
          this.get_number_default(this.skin_feel_data.warmth),
          this.get_number_default(this.skin_feel_data.total),
        ],
        ave: [
          this.get_number_default(this.skin_feel_score.softness),
          this.get_number_default(this.skin_feel_score.prickle),
          this.get_number_default(this.skin_feel_score.warmth),
          this.get_number_default(this.skin_feel_score.total),
        ],
        color: "#13C56B",
        name: this.name,
        chartDom: document.querySelector(`#${this.skinChartId}`),
      };
      radarChart(options, 'pc', 'skin');
    },
    number_fixed(data) {
      if (data == "") {
        return data;
      }
      return parseFloat(data.toFixed(2));
    },
    get_number_default(data) {
      if (data == "") {
        return 0;
      }
      return data;
    },
    star_classes(star) {
      const arr = [];
      for (let i = 0; i < 5; i++) {
        if (star <= 0) {
          arr[i] = "ri-star-line";
          continue;
        }
        let current = i + 1;
        if (current <= star) {
          arr[i] = "ri-star-fill";
        } else if (current - 0.5 <= star) {
          arr[i] = "ri-star-half-fill";
          break;
        } else {
          arr[i] = "ri-star-line";
        }
      }
      if (arr.length < 5) {
        for (let i = arr.length; i < 5; i++) {
          arr[i] = "ri-star-line";
        }
      }
      return arr;
    },
    // 获取当前面料的手感肤感评分
    getFabricScore() {
      this.hand_feel_star.roughness = this.number_fixed(
          _.get(this.currentHandFabric, "hand_feel_value.hand_smooth", "")
      );
      this.hand_feel_star.softness = this.number_fixed(
          _.get(this.currentHandFabric, "hand_feel_value.hand_stiff", "")
      );
      this.hand_feel_star.warmth = this.number_fixed(
          _.get(this.currentHandFabric, "hand_feel_value.hand_cool", "")
      );
      this.hand_feel_star.total = this.number_fixed(
          _.get(this.currentHandFabric, "hand_feel_value.hand_total", "")
      );

      this.hand_feel_data.roughness = this.number_fixed(
          _.get(this.currentHandFabric, "hand_feel.hand_smooth", 0)
      );
      this.hand_feel_data.softness = this.number_fixed(
          _.get(this.currentHandFabric, "hand_feel.hand_stiff", 0)
      );
      this.hand_feel_data.warmth = this.number_fixed(
          _.get(this.currentHandFabric, "hand_feel.hand_cool", 0)
      );
      this.hand_feel_data.total = this.number_fixed(
          _.get(this.currentHandFabric, "hand_feel.hand_total", 0)
      );

      this.hand_feel_score.roughness = this.number_fixed(
          _.get(this.currentHandFabric, "avg_score.hand_smooth", 0)
      );
      this.hand_feel_score.softness = this.number_fixed(
          _.get(this.currentHandFabric, "avg_score.hand_stiff", 0)
      );
      this.hand_feel_score.warmth = this.number_fixed(
          _.get(this.currentHandFabric, "avg_score.hand_cool", 0)
      );
      this.hand_feel_score.total = this.number_fixed(
          _.get(this.currentHandFabric, "avg_score.hand_total", 0)
      );

      this.skin_feel_star.prickle = this.number_fixed(
          _.get(this.currentSkinFabric, "skin_comfort_value.skin_smooth", "")
      );
      this.skin_feel_star.softness = this.number_fixed(
          _.get(this.currentSkinFabric, "skin_comfort_value.skin_stiff", "")
      );
      this.skin_feel_star.warmth = this.number_fixed(
          _.get(this.currentSkinFabric, "skin_comfort_value.skin_cool", "")
      );
      this.skin_feel_star.total = this.number_fixed(
          _.get(this.currentSkinFabric, "skin_comfort_value.skin_total", "")
      );

      this.skin_feel_data.prickle = this.number_fixed(
          _.get(this.currentSkinFabric, "skin_comfort.skin_smooth", 0)
      );
      this.skin_feel_data.softness = this.number_fixed(
          _.get(this.currentSkinFabric, "skin_comfort.skin_stiff", 0)
      );
      this.skin_feel_data.warmth = this.number_fixed(
          _.get(this.currentSkinFabric, "skin_comfort.skin_cool", 0)
      );
      this.skin_feel_data.total = this.number_fixed(
          _.get(this.currentSkinFabric, "skin_comfort.skin_total", 0)
      );

      this.skin_feel_score.prickle = this.number_fixed(
          _.get(this.currentSkinFabric, "avg_score.skin_smooth", 0)
      );
      this.skin_feel_score.softness = this.number_fixed(
          _.get(this.currentSkinFabric, "avg_score.skin_stiff", 0)
      );
      this.skin_feel_score.warmth = this.number_fixed(
          _.get(this.currentSkinFabric, "avg_score.skin_cool", 0)
      );
      this.skin_feel_score.total = this.number_fixed(
          _.get(this.currentSkinFabric, "avg_score.skin_total", 0)
      );

      this.$nextTick(() => {
        this.initChart();
      });
    },
    setCurrentHandFabric(item) {
      this.currentHandFabric = item;
      this.getFabricScore()
    },
    setCurrentSkinFabric(item) {
      this.currentSkinFabric = item;
      this.getFabricScore()
    },
  },
  watch: {
    detailForm: {
      handler(val) {
        if (val) {
          this.name = _.get(val, "name", "");
          this.handFabrics = _.get(val, "all_fabrics_hand", []);
          this.skinFabrics = _.get(val, "all_fabrics_skin", []);
          this.currentHandFabric = _.get(val, "all_fabrics_hand.0", {});
          this.currentSkinFabric = _.get(val, "all_fabrics_skin.0", {});
          this.getFabricScore()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  components: {
    FeelTipBar,
  },
};
</script>

<style scoped lang="scss">
.hand_img,
.skin_img {
  img {
    width: 20rem;
    height: 22rem;
  }
}
.sample-title {
  margin-bottom: 2.5rem;
  margin-left: -1.2rem;
  margin-top: 2rem;
}
.sample-title-first {
  margin-top: 0;
}
.chart-options {
  position: absolute;
  top: 3px;
  right: 0;
}
</style>
