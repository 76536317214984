<template>
  <b-row class="pb-4">
    <b-col :cols="cols" v-for="(item, index) in list" :key="index">
      <div :class="[index >= 12 / cols && 'mt-4']">
        <div style="font-weight: bold">
          <label class="form-label">
            <span>{{ item.index_name }}</span>
            <i
              :id="item.field_name"
              class="ri-question-line"
              @mouseover="mouseOver(item)"
              @mouseleave="mouseLeave($event,item)"
            ></i>
          </label>
        </div>
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            :placeholder="$t('attribute.value')"
            v-model="item.value"
            @input="inputChange"
          />
          <span class="input-group-text input-group-text-wraper">
            <input
                type="text"
                class="form-control"
                v-model="item.unit"
                :placeholder="$t('attribute.unit')"
                @input="inputChange"
            />
          </span>
<!--          <span v-if="item.unit1.length == 1" class="input-group-text">{{-->
<!--            item.unit1[0]-->
<!--          }}</span>-->
<!--          <span v-else class="input-group-text" style="padding-right: 0px">-->
<!--            &lt;!&ndash; <select style="border: none; background-color:inherit" :options="item.unit1"></select> &ndash;&gt;-->
<!--            <b-form-select-->
<!--              v-model="item.unit"-->
<!--              :options="item.unit1"-->
<!--              style="-->
<!--                border: none;-->
<!--                background-color: inherit;-->
<!--                padding: 0px 35px 0px 0px;-->
<!--              "-->
<!--            ></b-form-select>-->
<!--          </span>-->
        </div>
      </div>
      <b-popover :target="item.field_name" v-model:show="item.showReference" triggers="">
        <div :ref="`reference${item.field_name}`" @mouseleave="item.showReference = false">
          <b-form-textarea
              v-model="item.reference"
              :placeholder="$t('attribute.reference')"
              rows="3"
          ></b-form-textarea>
        </div>
      </b-popover>
    </b-col>
  </b-row>
</template>

<script>
import _ from "lodash";
export default {
  emits: ["update:addForm"],
  props: {
    addForm: {
      type: Object,
      default: () => {},
    },
    detailForm: {
      type: Object,
      default: () => {},
    },
    flag: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      cols: 6,
      list: [],
    };
  },
  methods: {
    mouseOver(item) {
      item.showReference = true;
    },
    mouseLeave(event,item) {
      // 获取鼠标离开时的元素
      let relatedTarget = event.relatedTarget;
      if (relatedTarget && relatedTarget.className == "popover-arrow") {
        return;
      }
      item.showReference = false;
    },
    inputChange() {},
    dataChange() {
      let info = _.cloneDeep(this.list);
      _.forEach(info, function (item) {
        delete item.index_name;
        // delete item.reference;
        // if (item.unit1 && item.unit1.length == 1) {
        //   item.unit = item.unit1[0];
        // }
        // delete item.unit1;
      });
      if (this.flag == 4) {
        const params = {
          business_index: info,
        };
        this.$emit("update:addForm", params);
      } else {
        const params = {
          envrionmental_index: info,
        };
        this.$emit("update:addForm", params);
      }
    },
    updateUI() {
      if (!this.detailForm) {
        return;
      }
      const detailFormData = _.cloneDeep(this.detailForm);
      if (this.flag == 4) {
        this.list = detailFormData.business_index;
      } else {
        this.list = detailFormData.envrionmental_index;
      }
      this.dataChange();
    },
  },
  watch: {
    detailForm: {
      handler(val) {
        if (val) {
          this.updateUI();
        }
      },
      deep: true,
      immediate: true,
    },
    flag: {
      handler() {
        this.updateUI();
      },
      deep: true,
      immediate: true,
    },
    list: {
      handler() {
        this.dataChange();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss"></style>
