<template>
  <!-- 基本信息 -->
  <div class="baseinfo">
    <div class="row mb-4 justify-content-between">
      <div class="col-3">
        <div>
          <label class="form-label">{{
            $t("attribute.body_coverage_ratio")
          }}</label>
          <input
            type="text"
            class="form-control"
            v-model="versionInfo.body_coverage_ratio"
          />
        </div>
      </div>
      <!-- <div class="col-3">
                <div>
                    <label class="form-label">{{$t('attribute.size')}}</label>
                    <input type="text" class="form-control" v-model="versionInfo.size" />
                </div>
            </div>
            <div class="col-3">
                <div>
                    <label class="form-label">{{$t('attribute.brand')}}</label>
                    <input type="text" class="form-control" v-model="versionInfo.brand_id" />
                </div>
            </div>
            <div class="col-3">
                <div>
                    <label class="form-label">{{$t('attribute.manufacturer')}}</label>
                    <input type="text" class="form-control" v-model="versionInfo.manufacturer_id" />
                </div>
            </div> -->
    </div>
  </div>
  <!-- 2D版型 -->
  <div class="mt-5">
    <label for="description-field" class="form-label fs-18">{{
      $t("attribute.version_2d")
    }}</label>
    <div class="text-muted">{{ $t("base.upload_file_limit") }}</div>
    <DropZone
      accept=".png,.jpg"
      @drop.prevent="drop"
      @change.prevent="selectedFile('2d')"
      style="min-height: 220px"
      ipt-id="fileInput2d"
      limit="50M"
    />
    <div class="text-center text-muted" v-if="!file_2d_preview.length">
      {{ $t("base.no_data") }}
    </div>
    <ul class="list-unstyled mb-0" id="dropzone-preview" v-else>
      <div
        class="border rounded"
        v-for="(file, index) of file_2d_preview"
        :key="index"
      >
        <div class="d-flex p-2">
          <div class="flex-grow-1">
            <div class="pt-1">
              <h5 class="fs-14 mb-1">
                <a :href="file.url" :download="file.url" class="text-break">
                  {{ file.filename }}
                </a>
              </h5>
            </div>
          </div>
          <div class="flex-shrink-0 ms-3">
            <button
              type="button"
              class="btn btn-sm btn-danger"
              @click="deleteFile(index, '2d')"
            >
              {{ $t("base.delete") }}
            </button>
          </div>
        </div>
      </div>
    </ul>
  </div>
  <!-- 虚拟合身度 -->
  <div class="mt-5">
    <label for="description-field" class="form-label fs-18">{{
      $t("attribute.fitting_virtual")
    }}</label>
    <div class="text-muted">{{ $t("base.upload_file_limit") }}</div>
    <DropZone
      accept=".png,.jpg"
      @drop.prevent="drop"
      @change.prevent="selectedFile('3d')"
      style="min-height: 220px"
      ipt-id="fileInput3d"
      limit="50M"
    />
    <div class="text-center text-muted" v-if="!file_3d_preview.length">
      {{ $t("base.no_data") }}
    </div>
    <ul class="list-unstyled mb-0" id="dropzone-preview" v-else>
      <div
        class="border rounded"
        v-for="(file, index) of file_3d_preview"
        :key="index"
      >
        <div class="d-flex p-2">
          <div class="flex-grow-1">
            <div class="pt-1">
              <h5 class="fs-14 mb-1">
                <a :href="file.url" :download="file.url" class="text-break">
                  {{ file.filename }}
                </a>
              </h5>
            </div>
          </div>
          <div class="flex-shrink-0 ms-3">
            <button
              type="button"
              class="btn btn-sm btn-danger"
              @click="deleteFile(index, '3d')"
            >
              {{ $t("base.delete") }}
            </button>
          </div>
        </div>
      </div>
    </ul>
    <div>
      <div>
        <label class="form-label">{{
          $t("attribute.fitting_virtual_link")
        }}</label>
        <input
          type="text"
          class="form-control"
          v-model="versionInfo.file_3d_url"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { uploadFile } from "@/service/index";
import { getFileValuesFromDetail } from "@/utils/fetch-data";

export default {
  data() {
    return {
      versionInfo: {
        body_coverage_ratio: "",
        size: "",
        brand_id: "",
        manufacturer_id: "",
        file_2d: [],
        file_3d: [],
        file_3d_url: "",
      },
      file_2d_preview: [],
      file_3d_preview: [],
      inited: false,
    };
  },
  props: {
    addForm: {
      type: Object,
      default: () => {},
    },
    detailForm: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["update:addForm"],
  watch: {
    versionInfo: {
      handler(val) {
        let re_form = _.cloneDeep(val);
        re_form.file_2d = val.file_2d;
        re_form.file_3d = val.file_3d;
        this.$emit("update:addForm", re_form);
      },
      deep: true,
    },
    detailForm: {
      handler(val) {
        if (val && !this.inited) {
          // 只在第一次赋值时执行
          this.inited = true
          const detailFormData = _.cloneDeep(val);
          this.versionInfo.body_coverage_ratio = _.get(
            detailFormData,
            "body_coverage_ratio",
            ""
          );
          this.versionInfo.size = _.get(detailFormData, "size", "");
          this.versionInfo.brand_id = _.get(detailFormData, "brand_id", "");
          this.versionInfo.manufacturer_id = _.get(
            detailFormData,
            "manufacturer_id",
            ""
          );
          this.versionInfo.file_3d_url = _.get(
            detailFormData,
            "file_3d_url",
            ""
          );

          if (!this.versionInfo.file_2d.length) {
            const file_2d_data = getFileValuesFromDetail(
              detailFormData,
              "file_2d"
            );
            this.versionInfo.file_2d = file_2d_data.ids;
            this.file_2d_preview = file_2d_data.previews;
          }

          if (!this.versionInfo.file_3d.length) {
            const file_3d_data = getFileValuesFromDetail(
              detailFormData,
              "file_3d"
            );
            this.versionInfo.file_3d = file_3d_data.ids;
            this.file_3d_preview = file_3d_data.previews;
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    selectedFile(type) {
      let dropzoneFile = document.querySelector(`#fileInput${type}`).files[0];
      if (!dropzoneFile) return;
      uploadFile(dropzoneFile)
        .then((res) => {
          this[`file_${type}_preview`].push(res.data.payload);
          this.versionInfo[`file_${type}`].push(res.data.payload.id);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    drop(e) {
      let dropzoneFile = e.dataTransfer.files[0];
      if (!dropzoneFile) return;
      // this.uploadFileSign(dropzoneFile);
    },
    deleteFile(i, type) {
      this.versionInfo[`file_${type}`] = _.filter(
        this.versionInfo[`file_${type}`],
        (o) => o != this[`file_${type}_preview`][i].id
      );
      this[`file_${type}_preview`].splice(i, 1);
    },
  },
  components: {},
};
</script>

<style scoped lang="scss"></style>
