<template>
  <!-- 热湿舒适度 -->
  <div
    :class="[
      'heat',
      $route.query.chooseType &&
        $route.query.chooseType == 'heat' &&
        'choose_border',
    ]"
    style="padding-bottom: 2rem;"
  >
    <label>{{ $t("radar_chart.heat") }}</label>
    <div class="card-header" style="padding: 0.2rem 1rem"></div>
    <b-row>
      <b-col>
        <div>
          <img
            style="width: 100%; height: 400px; object-fit: contain"
            src="@/assets/img/thermal_comfort.png"
            alt=""
          />
        </div>
      </b-col>
      <b-col cols="4">
        <div
          class="hand_info"
          style="position: relative; top: 50%; transform: translateY(-50%)"
        >
          <div class="py-3">
            <label class="form-label" :class="[!isAdmin() ? 'sample-title' : '']">{{
              $t("radar_chart.comfort_value")
            }}</label>
            <b-row>
              <b-col v-if="isAdmin()">
                <div class="d-flex align-items-center">
                  <input
                    type="text"
                    class="form-control"
                    :value="comfort_star.comfort_value"
                    disabled
                  />
                  <div class="text-warning fs-20 ms-2">
                    <i
                      v-for="(cls, idx) in starUI(comfort_data.comfort_value)"
                      :key="idx"
                      :class="cls"
                    ></i>
                  </div>
                </div>
              </b-col>
              <b-col>
                <feel-tip-bar bar-type="comfort_value" :value="comfort_data.comfort_value"></feel-tip-bar>
              </b-col>
            </b-row>

          </div>
          <div class="py-3">
            <label class="form-label" :class="[!isAdmin() ? 'sample-title' : '']">{{
              $t("radar_chart.dampness_sensation")
            }}</label>
            <b-row>
              <b-col v-if="isAdmin()">
                <div class="d-flex align-items-center">
                  <input
                    type="text"
                    class="form-control"
                    :value="comfort_star.dampness_sensation"
                    disabled
                  />
                  <div class="text-warning fs-20 ms-2">
                    <i
                      v-for="(cls, idx) in starUI(comfort_data.dampness_sensation)"
                      :key="idx"
                      :class="cls"
                    ></i>
                  </div>
                </div>
              </b-col>
              <b-col>
                <feel-tip-bar bar-type="dampness_sensation" :value="comfort_data.dampness_sensation"></feel-tip-bar>
              </b-col>
            </b-row>
          </div>
          <div class="py-3">
            <label class="form-label" :class="[!isAdmin() ? 'sample-title' : '']">{{
              $t("radar_chart.thermal_sensation")
            }}</label>
            <b-row>
              <b-col v-if="isAdmin()">
                <div class="d-flex align-items-center">
                  <input
                    type="text"
                    class="form-control"
                    :value="comfort_star.thermal_sensation"
                    disabled
                  />
                  <div class="text-warning fs-20 ms-2">
                    <i
                      v-for="(cls, idx) in starUI(comfort_data.thermal_sensation)"
                      :key="idx"
                      :class="cls"
                    ></i>
                  </div>
                </div>
              </b-col>
              <b-col>
                <feel-tip-bar bar-type="thermal_sensation" :value="comfort_data.thermal_sensation"></feel-tip-bar>
              </b-col>
            </b-row>
          </div>
          <div class="py-3" v-if="false">
            <label class="form-label">{{ $t("radar_chart.total") }}</label>
            <b-row>
              <b-col>
                <div class="d-flex align-items-center">
                  <input
                    type="text"
                    class="form-control"
                    :value="comfort_star.total"
                    disabled
                  />
                  <div class="text-warning fs-20 ms-2">
                    <i
                      v-for="(cls, idx) in starUI(comfort_data.total)"
                      :key="idx"
                      :class="cls"
                    ></i>
                  </div>
                </div>
              </b-col>
              <b-col></b-col>
            </b-row>

          </div>
        </div>
      </b-col>
      <b-col>
        <div class="hand_chart ms-5">
          <div :id="heatChartId" style="height: 400px; width: 450px"></div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { radar1Chart } from "@/utils/chart.js";
import _ from "lodash";
import { starUI } from "@/utils/common";
import { isAdmin } from "@/utils/permission.js";
import FeelTipBar from "@/components/feel-tip-bar.vue";

export default {
  props: {
    detailForm: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      heatChartId: `heat_chart${+new Date()}`,
      comfort_star: {
        dampness_sensation: "",
        thermal_sensation: "",
        comfort_value: "",
        total: "",
      },
      comfort_data: {
        dampness_sensation: "",
        thermal_sensation: "",
        comfort_value: "",
        total: "",
      },
      comfort_score: {
        dampness_sensation: "",
        thermal_sensation: "",
        comfort_value: "",
        total: "",
      },
    };
  },
  mounted() {
    // this.initChart();
  },
  methods: {
    starUI,
    isAdmin,
    initChart() {
      this.heatFeelChart();
    },
    heatFeelChart() {
      let options = {
        data: [
          this.get_number_default(this.comfort_data.comfort_value),
          this.get_number_default(this.comfort_data.dampness_sensation),
          this.get_number_default(this.comfort_data.thermal_sensation),
          this.get_number_default(this.comfort_data.total),
        ],
        ave: [
          this.get_number_default(this.comfort_score.comfort_value),
          this.get_number_default(this.comfort_score.dampness_sensation),
          this.get_number_default(this.comfort_score.thermal_sensation),
          this.get_number_default(this.comfort_score.total),
        ],
        color: "#ED5E5E",
        name: this.$t("radar_chart.heat"),
        chartDom: document.querySelector(`#${this.heatChartId}`),
      };
      radar1Chart(options);
    },
    number_fixed(data) {
      if (data == "") {
        return data;
      }
      return parseFloat(data.toFixed(2));
    },
    get_number_default(data) {
      if (data == "") {
        return 0;
      }
      return data;
    },
    star_classes(star) {
      star = star * 5;
      const arr = [];
      for (let i = 0; i < 5; i++) {
        if (star <= 0) {
          arr[i] = "ri-star-line";
          continue;
        }
        let current = i + 1;
        if (current <= star) {
          arr[i] = "ri-star-fill";
        } else if (current - 0.5 <= star) {
          arr[i] = "ri-star-half-fill";
          break;
        } else {
          arr[i] = "ri-star-line";
        }
      }
      if (arr.length < 5) {
        for (let i = arr.length; i < 5; i++) {
          arr[i] = "ri-star-line";
        }
      }
      return arr;
    },
  },
  watch: {
    detailForm: {
      handler(val) {
        if (val) {
          this.comfort_star.dampness_sensation = this.number_fixed(
            _.get(val, "dampness_sensation", "")
          );
          this.comfort_star.thermal_sensation = this.number_fixed(
            _.get(val, "thermal_sensation", "")
          );
          this.comfort_star.comfort_value = this.number_fixed(
            _.get(val, "comfort_value", "")
          );
          this.comfort_star.total = this.number_fixed(_.get(val, "total", ""));

          this.comfort_data.dampness_sensation = this.number_fixed(
            _.get(val, "score_dat.ds_normalized", 0)
          );
          this.comfort_data.thermal_sensation = this.number_fixed(
            _.get(val, "score_dat.ts_normalized", 0)
          );
          this.comfort_data.comfort_value = this.number_fixed(
            _.get(val, "score_dat.cv_normalized", 0)
          );
          this.comfort_data.total = this.number_fixed(
            _.get(val, "score_dat.t_normalized", 0)
          );

          this.comfort_score.dampness_sensation = this.number_fixed(
            _.get(val, "avg_score_dat.ds_normalized", 0)
          );
          this.comfort_score.thermal_sensation = this.number_fixed(
            _.get(val, "avg_score_dat.ts_normalized", 0)
          );
          this.comfort_score.comfort_value = this.number_fixed(
            _.get(val, "avg_score_dat.cv_normalized", 0)
          );
          this.comfort_score.total = this.number_fixed(
            _.get(val, "avg_score_dat.t_normalized", 0)
          );

          this.initChart();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  components: {
    FeelTipBar,
  },
};
</script>

<style scoped lang="scss">
.sample-title {
  margin-bottom: 2.5rem;
  margin-left: -1.2rem;
  margin-top: 2rem;
}
</style>
