<template>
  <div class="comfortable">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <div class="table_tab flex-1">
        <ul
            class="nav nav-tabs nav-tabs-custom nav-success py-0 border-bottom-0"
            role="tablist"
        >
          <li class="nav-item" v-for="(tab, i) in tabInfo" :key="i">
            <a
                :class="[
              'nav-link',
              'All',
              'py-3',
              'position-relative cursor',
              i == current_tab && 'active',
            ]"
                @click="handlerToggleTab(i)"
            >{{ tab.title }}</a
            >
          </li>
        </ul>
      </div>
      <div v-if="showType != 'view'">
        <button
          type="button"
          class="btn btn-primary me-3"
          @click="openRelatedIndicators"
        >
          <i class="ri-equalizer-fill me-1 align-bottom"></i>
          {{ $t("attribute.indicators_related") }}
        </button>
      </div>
    </div>

    <View
      v-if="showType == 'view'"
      v-model:addForm="addForm"
      :detailForm="formList"
      :flag="type"
    ></View>
    <Add
      v-else
      v-model:addForm="addForm"
      :detailForm="formList"
      :flag="type"
    ></Add>
    <RelatedIndicators ref="relatedIndicators" @save="updateIndexRelation"></RelatedIndicators>
    <message-alert
        :alertInfo="alertInfo"
        v-if="formValidataAlert"
    ></message-alert>
  </div>
</template>

<script>
import Add from "./quality/Add.vue";
import View from "./quality/View.vue";
import RelatedIndicators from "@/views/components/RelatedIndicators";
import _ from "lodash";
import MsgAlert from "@/mixins/MsgAlert";
export default {
  data() {
    return {
      current_tab: 0,
      addForm: {},
      tabInfo: [
        {
          title: this.$t("attribute.structure"),
          type: "structure",
        },
        {
          title: this.$t("attribute.safety"),
          type: "safety",
        },
        {
          title: this.$t("attribute.protection"),
          type: "protection",
        },
        {
          title: this.$t("attribute.features"),
          type: "features",
        },
        {
          title: this.$t("attribute.others"),
          type: "other",
        },
      ],
      formList: {
        structure: [],
        safety: [],
        protection: [],
        features: [],
        other: [],
      },
      type: "structure",
    };
  },
  mixins: [MsgAlert],
  props: {
    showType: {
      type: String,
      default: "",
    },
    detailForm: {
      type: Object,
      default: () => {},
    },
    searchList: {
      type: Object,
      default: () => {},
    },
    submitForm: {
      type: Object,
      default: () => {},
    },
    hscode: {
      type: String,
      default: "",
    },
  },
  emits: ["update:submitForm", 'updateIndexRelation'],
  watch: {
    detailForm: {
      handler(val) {
        if (val) {
          let quality = _.get(_.cloneDeep(val), "quality_index",[]);
          for (let key in this.formList) {
            this.formList[key] = [];
          }
          quality.forEach((item) => {
            this.tabInfo.forEach((tab) => {
              if (tab.type == item.index_category) {
                this.formList[tab.type].push(item);
              }
            });
          });
        }
      },
      deep: true,
    },
    addForm: {
      handler(val) {
        let submit = _.mergeWith(this.submitForm, val, (objV, srcV) => {
          return srcV;
        });
        this.$emit("update:submitForm", submit);
      },
      deep: true,
    },
  },
  methods: {
    openRelatedIndicators() {
      const param = {
        key: 'garment',
        code: this.hscode || this.detailForm['code'],
        index_type: 'quality_index',
        data: this.detailForm['all_quality_index'],
      }
      if (!param.code) {
        this.errorAlert(this.$t('attribute.please_set_hscode'));
        return
      }
      console.log('打开关联指标弹窗时获取的参数：', param)
      // console.log('quality.openRelatedIndicators:',param)
      this.$refs.relatedIndicators.open(param);
    },
    updateIndexRelation(relations) {
      this.$emit('updateIndexRelation', relations);
    },
    handlerToggleTab(index) {
      if (this.current_tab == index) {
        return;
      }
      this.current_tab = index;
      switch (index) {
        case 0:
          this.type = "structure";
          break;
        case 1:
          this.type = "safety";
          break;
        case 2:
          this.type = "protection";
          break;
        case 3:
          this.type = "features";
          break;
        case 4:
          this.type = "other";
          break;
      }
    },
  },
  components: {
    Add,
    View,
    RelatedIndicators,
  },
};
</script>

<style scoped lang='less'>
</style>
