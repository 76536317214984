<template>
  <!-- 基本信息 -->
  <!-- <div class="baseinfo">
    <div
      :class="['row text-gray', index != 0 && 'mt-4']"
      v-for="(base, index) in baseInfo"
      :key="index"
    >
      <div class="col-3">{{ base.title }}</div>
      <div class="col">{{ base.value }}</div>
    </div>
  </div> -->
  <!-- 织物 -->
  <div class="fabric">
    <label>{{ $t("fabric.name") }}</label>
    <table class="table table-nowrap table-bordered my-3">
      <thead class="table-light">
        <tr>
          <th v-for="(head, index) in fabric_head" :key="index">{{ head }}</th>
        </tr>
      </thead>
      <tbody style="vertical-align: middle" v-if="fabricList">
        <tr v-for="(info, index) in fabricList" :key="index">
          <td>{{ info.no_fabric_layers }}</td>
          <td>
            <a
              class="text-decoration-underline cursor"
              target="_blank"
              @click="jump2Fabric(info.id)"
              >{{ info.fabric_id }}</a
            >
          </td>
          <td>{{ getStructureLabel(info) }}</td>
          <td>{{ info.color }}</td>
          <td>{{ info.designer_id }}</td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="fabricList == undefined || fabricList.length == 0"
      style="position: relative; height: 40px"
    >
      <Empty flag="2" :content="$t('base.none')"></Empty>
    </div>
  </div>
  <!-- 款式 -->
  <!-- <div>
		<label>{{ $t('attribute.style') }}</label>
		<div class="card-header" style="padding: 0.2rem 1rem"></div>
		<div class="mt-3">
			<ul class="d-flex">
				<li v-for="(img, index) in shapeList" :key="index" style="width: 150px; height: 200px" class="me-3">
					<a href="javascript:;" @click="handleImgPreview(img)">
						<img
							:src="img"
							alt=""
							style="object-fit: contain;"
							@error="
								e => {
									e.target.src = defaultImage;
								}
							"
						/>
					</a>
				</li>
			</ul>
		</div>
	</div> -->
  <!-- <PreviewImg
    v-model:showPreviewImg="showPreviewImg"
    :src="src"
    v-if="showPreviewImg"
  ></PreviewImg> -->
</template>

<script>
// import PreviewImg from "@/components/common/PreviewImg";
import defaultImage from "@/assets/image.png";
import { getSankeyChartInfo } from "@/service";
import _ from "lodash";
import { structure_options } from "@/views/fabric/datalist";
import { fit_options } from "../../datalist";

const structureOptionsWithKey = _.keyBy(structure_options, "value");

export default {
  data() {
    return {
      defaultImage,
      baseInfo: [
        {
          title: this.$t("attribute.size"),
          key: "sizing",
          value: "",
        },
        {
          title: this.$t("attribute.fitting"),
          key: "fitting",
          value: "",
        },
      ],
      fabric_head: [
        this.$t("fabric.no_layers"),
        this.$t("fabric.id"),
        this.$t("fabric.structure_layer"),
        this.$t("fabric.color"),
        this.$t("attribute.designer"),
      ],
      fabricList: [],
      fabricChartId: `fabric_chart${+new Date()}`,
      shapeList: [],
      structureOptionsWithKey,
      showPreviewImg: false,
      src: "",
      sankey: {},
    };
  },
  props: {
    detailForm: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    detailForm: {
      handler(val) {
        if (val) {
          this.baseInfo.forEach((info) => {
            for (let v in val) {
              if (info.key == v) {
                if (info.key == "fitting") {
                  info.value = this.fitting(val[v]);
                } else if (info.key == "code") {
                  const code = val[v];
                  const code_2 = val["code_2"];
                  const code_ciq = val["code_ciq"];
                  let value = "";
                  if (code) {
                    value += code + "-";
                  }
                  if (code_2) {
                    value += code_2 + "-";
                  } else {
                    value += "-";
                  }
                  if (code_ciq) {
                    value += code_ciq;
                  }
                  info.value = value;
                } else {
                  info.value = val[v];
                }
              }
            }
          });
          let style = _.get(val, "style", []);
          if (style == "[]") {
            style = [];
          }

          this.shapeList = style.length ? _.map(style, "url") : [];
          this.fabricList = _.get(val, "fabrics", []);
          // this.requestSankeyChart();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    fitting(fitting) {
      let i = 0;
      for (i = 0; i < fit_options.length; i++) {
        const item = fit_options[i];
        if (item.key == fitting) {
          return item.value;
        }
      }
      return fitting;
    },
    requestSankeyChart() {
      const params = {
        id: this.detailForm.id,
        key: this.detailForm.key,
      };
      getSankeyChartInfo(params)
        .then((res) => {
          if (res && res.payload) {
            this.sankey = res.payload;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    jump2Fabric(f_id) {
      if (f_id) {
        this.$router.push(`/fabric_detail/?id=${f_id}&type=view`);
      }
    },
    getStructureLabel(info) {
      let structure = _.get(info, "layer", "");
      if (!structure) {
        return "";
      }
      let item = this.structureOptionsWithKey[structure];
      if (!item) {
        return "";
      }
      return item.label;
    },
    handleImgPreview(item) {
      this.src = item;
      if (this.src == "") {
        return;
      }
      this.showPreviewImg = true;
    },
  },
  computed: {},
  components: {
    // PreviewImg,
  },
};
</script>

<style scoped lang="less"></style>
